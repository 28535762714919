import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'

Vue.use(ViewUI)

Vue.config.productionTip = false

Date.prototype.toLocaleString = function () {   // 重写日期函数格式化日期
  return `${this.getFullYear()}-${this.getMonth() + 1 >= 10 ? (this.getMonth() + 1) : '0' + (this.getMonth() + 1)}-${this.getDate() >= 10 ? this.getDate() : '0' + this.getDate()} ${this.getHours() >= 10 ? this.getHours() : '0' + this.getHours()} : ${this.getMinutes()>=10?this.getMinutes():'0'+this.getMinutes()} : ${this.getSeconds() >= 10 ? this.getSeconds() : '0' + this.getSeconds()}`;
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
